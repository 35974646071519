/**
 * Copyright (C) SPREAD WORKS Inc. All Rights Reserved.
 * 
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
'use strict';

// import './_slider';
import './_main';
import './_information_bar';

window.jQuery = $;
window.$ = $;
