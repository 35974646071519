/**!
 * Copyright (C) SPREAD WORKS Inc. All Rights Reserved.
 * 
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
const PerfectScrollbar = require('perfect-scrollbar').default;
window.PerfectScrollbar = PerfectScrollbar;

const bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.bootstrap = bootstrap;

import Swiper from 'swiper/bundle';
window.Swiper = Swiper;

/**
 * @var メガメニューのスクロールバー
 */
var currentPerfectScrollbar = null;

/**
 * @var カート用スライダー
 */
var cartConfirmListSwiper = null;

document.addEventListener("DOMContentLoaded", function() {

    //
    // トップページのメインビジュアル
    //
    if (document.getElementById("taba-top-main-visual") != null) {
        let topMainVisualSwiper = new Swiper('#taba-top-main-visual', {
            loop: true, 
            effect: 'fade',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
            autoplay: {
                delay: 10000,
            },
        });
    }

    //
    // スクロールアニメーション
    //
    var scrollElem = undefined;
    let scrollAnimation = function() {
        if (scrollElem == undefined) scrollElem = document.querySelectorAll('.taba-anime');
        for (let i = 0; i < scrollElem.length; i++) {
            let trigger = 50;
            if (window.innerHeight > scrollElem[i].getBoundingClientRect().top + trigger) {
                scrollElem[i].classList.add('taba-effect');
            } else {
                scrollElem[i].classList.remove('taba-effect');
            }
        }
    }
    window.addEventListener('load', scrollAnimation);
    window.addEventListener('scroll', scrollAnimation);

    //
    // 商品画像
    //
    if (document.getElementById("taba-product-image-thumb") != null && document.getElementById("taba-product-image") != null) {
        let productImageThumbSwiper = new Swiper('#taba-product-image-thumb', {
            slidesPerView: 5,
            autoHeight: true,
            watchSlidesProgress: true,
        });

        let productImageSwiper = new Swiper('#taba-product-image', {
            loop: true,
            autoHeight: true,
            thumbs: {
            swiper: productImageThumbSwiper,
            },
        });
    }

    //
    // カート
    //
    cartConfirmListSwiper = createCartConfirmListSwiper();

    document.querySelectorAll('.taba-product-slider').forEach(element => {
        new Swiper(element, {
            slidesPerView: 2,
            spaceBetween: 15,
            loop: false,
            // navigation: {
            //     nextEl: element.parentNode.querySelector('.swiper-button-next'),
            //     prevEl: element.parentNode.querySelector('.swiper-button-prev'),
            // },
            pagination: {
                el: element.parentNode.querySelector('.swiper-pagination'),
                type: 'bullets',
                clickable: true,
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 4,
                },
                1200: {
                    slidesPerView: 5,
                },
            },
        });
    });

    //
    // CSRF
    //
    $.ajaxSetup({
        'headers' : {
            'ECCUBE-CSRF-TOKEN' : $('meta[name="eccube-csrf-token"]').attr('content')
        }
    });

    //
    // ローディング
    //
    $(window).on('load',function(){
        $('body').addClass('taba-loaded');
    });
    // ローディングバー
    if ($('img')[0]) {
        var loadCount = 0;
        var imgLength = $('img').length;
        $("img").each(function() {
            var src = $(this).attr("src");
            $("<img>").attr('src',src).on('load',function(){
                loadCount++;
            });
        });
        var loadingTimer = setInterval(function() {
            $(".taba-loading-bar").css({
                "width": (loadCount / imgLength) * 100 + "%"
            });
            if ((loadCount / imgLength) * 100 == 100) {
                clearInterval(loadingTimer);
                $(".taba-loading-bar").delay(200).animate({
                    "opacity": 0
                }, 200);
            }
        },5);
    }
    setInterval(function() {
        // 何らかの問題でローディングが終わらない場合
        // 5秒経過後に、taba-loaded を追加する。
        if ($('body').hasClass("taba-loaded") == false) {
            $('body').addClass('taba-loaded');
        }
        // 404の時にローディングバーが途中で止まるため
        // 5秒経過した場合、強制的にローディングバーを消す
        $(".taba-loading-bar").css({"width":"100%"}).delay(1000).animate({"opacity": 0},1000).remove();
    },5000);

    //
    // ページ遷移アニメーション
    //
    $('a:not([href^="#"]):not([target])').on('click', function(e){
        loading();
    });

    //
    // 入力バリデーション
    //
    if ($(".needs-validation")[0]) {
        // エラーが解消されるまで、送信できないようにします
        var forms = document.getElementsByClassName('needs-validation');
        var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener('submit', function (event) {
                form.classList.add('was-validated');
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                    $(form).find('.form-group').each(function(){
                        formGroup = $(this);
                        if (formGroup.find('.form-control:invalid,.form-check-input:invalid')[0]) {
                            formGroup.addClass('taba-is-invalid-style');
                            formGroup.find('.invalid-feedback').css('display','block');
                        }
                    });
                } else {
                    loading();
                }
            }, false);
        });

        // 初期表示設定
        $('.needs-validation .form-group .form-control.is-invalid').each(function() {
            // 入力エリアの背景に色をつける
            $(this).closest(".form-group").addClass('taba-is-invalid-style');
            // サーバー側のメッセージのみ表示する
            invalidFeedback = $(this).next('.invalid-feedback');
            if (invalidFeedback.find('.taba-message-server')[0]) {
                invalidFeedback.find('.taba-message-client').hide();
            }
        });

        // 入力内容に変更があった場合、サーバー側のエラーメッセージを削除する
        $('.needs-validation .form-group .form-control').on('change',function(){
                $(this).removeClass('is-invalid');
                invalidFeedback = $(this).next('.invalid-feedback');
                invalidFeedback.find('.taba-message-server').remove();
                invalidFeedback.find('.taba-message-client').show();
        });

        // 入力後にバリデーションの状態によって、
        // 入力エリアの背景色を変更及び
        // チェックボックスとラジオボタンのエラーメッセージを表示させます
        $('.needs-validation .form-group .form-control,.needs-validation .form-group .form-check-input').on('focusout keyup change',function(){
            parent = $(this).closest(".form-group");
            isInvalid = false;
            hasFeedback = true;
            parent.find('.form-control:invalid,.form-check-input:invalid').each(function(){
                isInvalid = true;
                $(this).addClass('is-invalid');
                feedback = $(this).next('.invalid-feedback');
                if (feedback[0]) {
                    feedback.show();
                    hasFeedback = false;
                }
            });
            parent.find('.form-control:valid,.form-check-input:valid').each(function(){
                $(this).removeClass('is-invalid');
                feedback = $(this).next('.invalid-feedback');
                if (feedback[0]) {
                    feedback.hide();
                    hasFeedback = false;
                }
            });
            if (isInvalid) {
                parent.addClass('taba-is-invalid-style');
                if (hasFeedback) parent.find('.invalid-feedback').show();
            } else {
                parent.removeClass('taba-is-invalid-style');
                if (hasFeedback) parent.find('.invalid-feedback').hide();
            }
        });
    }

    //
    // 商品検索
    //

    // 絞り込み
    if ($('.cid-selector')[0]) {
        $('.cid-selector').change(function() {
            location.href = "?cid=" + $(this).val();
            loading();
        });
    }

    // 表示件数を変更
    if ($('.disp-number')[0]) {
        $('.disp-number').change(function() {
            var dispNumber = $(this).val();
            $('#disp_number').val(dispNumber);
            $('#pageno').val(1);
            $("#taba-product-search").submit();
            loading();
        });
    }

    // 並び順を変更
    if ($('.order-by')[0]) {
        $('.order-by').change(function() {
            var orderBy = $(this).val();
            $('#orderby').val(orderBy);
            $('#pageno').val(1);
            $("#taba-product-search").submit();
            loading();
        });
    }

    // お気に入り
    if ($(".taba-favorite")[0]) {
        $(".taba-favorite").each(function(){
            $(this).favariteControl();
        });
    }

    // カート追加
    if ($(".taba-cart.add-cart")[0]) {
        $('.taba-cart.add-cart').cartControl();
    }

    //
    // バナー / トップページ
    //
    if ($("#taba-banner-top")[0]) {


        // let taba_banner_top_owl = $("#taba-banner-top").owlCarousel({
        //     items: 1,
        //     loop: true,
        //     center: true,
        //     // autoHeight: true,
        //     // autowidth: true,
        //     // singleItem:true,
        //     nav: true,
        //     navText: ['<i class="material-icons">chevron_left</i>','<i class="material-icons">chevron_right</i>'],
        //     dots: true,
        //     pullDrag: false,
        //     // animateOut: 'fadeOut',
        //     autoplay: false,
        //     autoplayTimeout:5000,
        //     autoplayHoverPause:true,
        //     margin: 0,
        //     widthFit: true,
        // });
        // taba_banner_top_owl.on('initialized.owl.carousel changed.owl.carousel',function(e) {
        //     var banner_id = $('.owl-item.active .item').attr('data-key');
        //     $.ajax({
        //         type: 'POST',
        //         url: TABA_JS_CONFIG.taba_banner_manager.view_counter_url,
        //         data: TABA_JS_CONFIG.csrf.name + "=" + TABA_JS_CONFIG.csrf.value + '&banner_id=' + banner_id,
        //         success: function(json) {},
        //         error: function() {}
        //     });
        // });
    }




    //
    // ナビゲーションのエフェクト
    //
    let $nav = $('#taba-site-navbar');
    let navPos = $('#taba-site-navbar').offset().top;
    let fixedClass = 'taba-navbar-fixed';
    let openedClass = 'taba-navbar-opened';
    var isScroll = false;
    var isDoropdown = false;
    var currentScrollY = $('html').scrollTop(); // スクロール位置を保存する; 

    const header = document.querySelector('taba-header');
    const observer = new IntersectionObserver(entries => {
    for (const entry of entries) {
        if (isDoropdown == false) {
            if (entry.isIntersecting) {
                $nav.removeClass(fixedClass);
                $nav.removeClass(openedClass);
                $('#taba-header-logo-area').removeClass('taba-small');
                isScroll = false;
            } else {
                $nav.addClass(fixedClass);
                $nav.removeClass(openedClass);
                $('#taba-header-logo-area').addClass('taba-small');
                isScroll = true;
            }
        } else {
            if (isScroll == true) {
                $nav.addClass(openedClass);
            }
        }
    }
    });
    observer.observe(document.getElementById('taba-header-top-border'));

    // メガメニューを閉じる
    $('#taba-nav-overlay , .taba-megamenu-close').on('click',function(event){
        // 初期化
        $('.taba-megamenu-nav').removeClass('active');
        $('.taba-megamenu').removeClass('show');
        $('.taba-informationbar').show();
        $('.taba-megamenu').css('height','auto');
        $('#taba-site-navbar').removeClass('taba-effect');
        $('#taba-nav-overlay').removeClass('active');
        $('html,body').css({overflow:'','overflow-y':'',height:'auto'});

        $('html,body').animate({"scrollTop":currentScrollY},0);
        

        if (currentPerfectScrollbar != null) {
            currentPerfectScrollbar.destroy();
            currentPerfectScrollbar = null;
        }
        isDoropdown = false;
    });
    $('#taba-nav-main .taba-megamenu-nav').on('click',function(event){
        let nav = $(this);
        let megamenu = $(this).next('.taba-megamenu');

        // 開く
        if (!nav.hasClass('active')) {
            currentScrollY = $('html').scrollTop();

            $('.taba-megamenu-nav').removeClass('active');
            nav.addClass('active');

            // 初期化
            $('.taba-megamenu.show').each(function(){
                $(this).removeClass('show');
                $(this).css('height','auto');
            });

            megamenu.addClass('show');
            isDoropdown = true;
            $('.taba-informationbar').hide();

            // メガメニューがWindowの高さより多き場合、
            // megamenuの高さを設定し、スクロールできるようにする
            // if (megamenu.outerHeight() + $('#taba-header').height() > $(window).height()) {
            //     megamenu.css('height',$(window).height() - $('#taba-header').height());
            // }

            $('#taba-site-navbar').addClass('taba-effect');
            $('#taba-nav-overlay').addClass('active');

            // 背面がスクロールしないようにする
            $('html').css({overflow:'auto',height:'100%'});
            // 背面にスクロールバーが表示されていた場合、メガメニュー表示状態でも強制的にスクロールバーを表示する
            if (window.innerWidth - $(window).width() > 0) {
                $('html').css({'overflow-y':'scroll'});
            }
            $('body').css({overflow:'hidden',height:'100%'});

            // スクロールバーを更新
            currentPerfectScrollbar = new PerfectScrollbar(megamenu.get(0), {
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollX: true,
            });
        }
        // 閉じる
        else {
            nav.removeClass('active');
            megamenu.removeClass('show');
            megamenu.css('height','auto');
            $('.taba-informationbar').show();
            $('#taba-site-navbar').removeClass('taba-effect');
            $('#taba-nav-overlay').removeClass('active');
            $('html,body').css({overflow:'','overflow-y':'',height:'auto'});
            // $('html,body').scrollTop(currentScrollY);
            $('html,body').animate({"scrollTop":currentScrollY},0);

            if (currentPerfectScrollbar != null) {
                currentPerfectScrollbar.destroy();
                currentPerfectScrollbar = null;
            }
            isDoropdown = false;
        }
    });

    //
    // スマホ用メニュー
    //
    if ($('#taba-nav-sp-menu')[0]) {
        let megamenu = $('#taba-nav-sp-menu > .taba-megamenu');
        $('.taba-nav-sp-item .taba-megamenu-nav').on('click',function(){
            let megamenuNav = $(this);
            megamenuNav.removeClass('active');
            let subMegamenu = $(this).next('.taba-megamenu');
            if (subMegamenu.hasClass('show')) {
                subMegamenu.removeClass('show');
            } else {
                megamenuNav.addClass('active');
                $('.taba-nav-sp-item .taba-megamenu.show').each(function(){
                    $(this).removeClass('show');
                });
                subMegamenu.addClass('show');
            }

            // スクロールバーの調整
            if (megamenu.outerHeight() + $('#taba-header').height() > $(window).height()) {
                megamenu.css('height',$(window).height() - $('#taba-header').height());
            } else {
                megamenu.css('height','auto');
            }
            currentPerfectScrollbar.update();
        });
    }

    //
    // スムーススクロール
    //
    var scrollSpeed = 450;
    $(window).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $(".taba-smooth-scroll").css('display','flex');
        } else {
            $(".taba-smooth-scroll").css('display','none');
        }
    });
    $('.taba-smooth-scroll').click(function () {
        var href = $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        // スクロール後にナビ部分をdisplay:fixedにするため、それ以降の要素の位置を再計算する必要がある。
        // 一旦、少し下げた後に、ターゲットの高さを計測し、再度スクロールアニメーションさせる。
        $("html,body").animate({
            scrollTop: $("#taba-header").outerHeight()
        }, 50, function () {
            //var position = target.offset().top;
            var position = 0;
            position -= $("#taba-site-navbar").outerHeight();
            $("html,body").animate({
                scrollTop: position
            }, scrollSpeed, "swing");
        });
        return false;
    });

    // ツリーメニュー
    if ($('.taba-tree')[0]) {
        $('.taba-tree .parent .action').on('click',function(){
            let parent = $(this).parent();
            if (parent.hasClass('active')) {
                parent.removeClass('active');
            } else {
                parent.addClass('active');
            }
            return false;
        });
    }

    //
    // 新商品カルーセル
    // 使用箇所：Block/taba_new_item.twig
    //
    if ($("#taba-top-arrival-carousel")[0]) {
        $("#taba-top-arrival-carousel").owlCarousel({
            items: 4,
            loop: true,
            center: false,
            nav: true,
            navText: ['<i class="material-icons">chevron_left</i>','<i class="material-icons">chevron_right</i>'],
            dots: true,
            pullDrag: false,
            margin: 15,
            responsive:{
                0:{
                    items:1,
                    nav:false,
                    center: true,
                    stagePadding: 45,
                },
                576:{
                    items:2,
                    nav:false,
                    center: true,
                    stagePadding: 45,
                },
                768:{ items:3 },
                992:{ items:4 },
                1200:{ items:4 }
            }
        });
    }

    //
    // 関連商品カルーセル
    // 使用箇所 Product/detail.twig
    //
    if ($("#taba-product-related")[0]) {
        $("#taba-product-related").owlCarousel({
            items: 5,
            loop: true,
            center: false,
            nav: true,
            navText: ['<i class="material-icons">chevron_left</i>','<i class="material-icons">chevron_right</i>'],
            dots: true,
            pullDrag: false,
            margin: 15,
            responsive:{
                0:{
                    items:2,
                    nav:false,
                    center: true,
                    stagePadding: 45,
                },
                576:{
                    items:2,
                    nav:false,
                    center: true,
                    stagePadding: 45,
                },
                768:{ items:3 },
                992:{ items:5 },
                1200:{ items:5 }
            }
        });
    }

    //
    // 数量変更
    //
    $('.taba-cart-number-control').on('click',function(){
        let field = $($(this).attr('data-field'));
        let num = 0;
        if ($(this).attr('data-type') == 'plus') {
            num = parseInt(field.val()) + 1;
            if (parseInt(field.attr('max')) >= num) {
                field.val(num);
            }
        } else {
            num = parseInt(field.val()) - 1;
            if (parseInt(field.attr('min')) <= num) {
                field.val(num);
            }
        }
        $(this).parent().parent().find('.taba-cart-number-control').each(function(index){
            if ($(this).attr('data-type') == 'plus') {
                if (field.attr('max') <= num) {
                    $(this).attr('disabled','disabled');
                } else {
                    $(this).attr('disabled',false);
                }
            }
            if ($(this).attr('data-type') == 'minus') {
                if (field.attr('min') >= num) {
                   $(this).attr('disabled','disabled');
                } else {
                    $(this).attr('disabled',false);
                }
            }
        });
    });
});



/**
 * カート追加
 *  
 * @param cartBlockUpdateUrl
 * @param errorMessage
 * @returns
 */
$.fn.cartControl = function() {
    return $(this).each(function(i,element){
        var cartBlockUpdateUrl = $(this).attr('data-url');
        var errorMessage = $(this).attr('data-error-message');
        var form = $(element).closest('form');

        //
        // 規格選択時に#productClassの値に設定する
        //
        var productClass = form.find('#ProductClass');
        var productClassSelector1 = form.find('#classcategory_id1');
        var productClassSelector2 = form.find('#classcategory_id2');
        if (productClassSelector2[0]) {
            productClassSelector2.prop('disabled', true);
            productClassSelector2.find('option').prop('disabled', true);
            productClassSelector1.on('change',function(){
                var productClassId1 = $(this).val();
                productClass.val('');
                productClassSelector2.prop('selectedIndex',0);
                let firstFlg = false;
                productClassSelector2.find('option').each(function(){
                    $(this).prop('selected', false);
                    if ($(this).attr('data-product-class-id1') != productClassId1) {
                        //$(this).hide().prop('disabled', true);
                        $(this).prop('disabled',true);
                        if (!$(this).closest('span')[0]) $(this).wrap('<span style="display:none;"></span>');
                    } else if ($(this).attr('data-stock') == 'false') {
                        // $(this).show().prop('disabled', true);
                        $(this).prop('disabled', true);
                        if ($(this).closest('span')[0]) $(this).unwrap(); 
                    } else {
                        //$(this).show().prop('disabled', false);
                        $(this).prop('disabled', false);
                        if (!firstFlg) {
                            productClass.val($(this).attr('data-id'));
                            $(this).prop('selected', true);
                            $('#taba-price-display').html($(this).attr('data-price'));
                            firstFlg = true;
                        }
                        if ($(this).closest('span')[0]) $(this).unwrap();
                    }
                });
                productClassSelector2.prop('disabled', false);
            });
            productClassSelector2.on('change',function(){
                // 選択されたoptionを取得
                let optionElement = null;
                productClassSelector2.children('option').each(function(){
                    if ($(this).prop('selected')) {
                        optionElement = $(this);
                    }
                });
                productClass.val(optionElement.attr('data-id'));
                // 価格表示変更
                if (optionElement.attr('data-price')) {
                    $('#taba-price-display').html(optionElement.attr('data-price'));
                } else {
                    $('#taba-price-display').html($('#taba-price-display').attr('data-default'));
                }
            });
        }
        else if (productClassSelector1[0]) {
            productClassSelector1.on('change',function(){
                // 選択されたoptionを取得
                let optionElement = null;
                productClassSelector1.children('option').each(function(){
                    if ($(this).prop('selected')) {
                        optionElement = $(this);
                    }
                });
                productClass.val(optionElement.attr('data-id'));
                // 価格表示変更
                if (optionElement.attr('data-price')) {
                    $('#taba-price-display').html(optionElement.attr('data-price'));
                } else {
                    $('#taba-price-display').html($('#taba-price-display').attr('data-default'));
                }
            });
        }

        $(element).on('click',function() {
            var button = $(this);
            var buttonOriginHtml = button.html();
            var navCart = $('#taba-cart-icon i');
            var navCartOriginHtml = navCart.html();
            var $form = $(this).parents('form');
            var loadingIcon = '<span class="p-0 m-0 taba-icon-loading taba-icon-nomargin taba-anime-spin-left"></span>';
            // $('.taba-nav-cart i').removeClass(animeClass);

            // メッセージを表示する要素
            let tooltipTarget = button;
            if (button.attr('data-tooltip-target')) {
                tooltipTarget = $(button.attr('data-tooltip-target'));
            }

            // 規格が選択されているかチェック
             if (productClassSelector1[0]) {
                 if (productClassSelector1.val() == "") {
                    tooltipTarget.tooltipManual(productClassSelector1.attr("data-error-message"));
                    button.find("i").addClassOnce('vivify duration-500 shake',600);
                    return;
                 }
             }
             if (productClassSelector2[0]) {
                 if (productClassSelector2.val() == "" || productClass.val() == "") {
                    tooltipTarget.tooltipManual(productClassSelector2.attr("data-error-message"));
                    button.find("i").addClassOnce('vivify duration-500 shake',600);
                    return;
                 }
             }

            let mesage = '';
            $.when(
                $.ajax({
                    url: $form.attr('action'),
                    type: $form.attr('method'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function(xhr, settings) {
                        // ウェイトカーソルに変更
                        $('html').addClass('taba-status-loading');
                        button.addClass('taba-status-loading');
                        setTimeout(function(){
                            $('html').removeClass('taba-status-loading');
                            button.removeClass('taba-status-loading');
                        },10000);
                        button.prop('disabled', true);

                        // 更新中アイコンに変更
                        button.html(loadingIcon);
                        navCart.html(loadingIcon);
                    }
                // 結果
                }).done(function(data) {
                    $.each(data.messages, function() { mesage += this; }); // メッセージ

                // 失敗
                }).fail(function(jqXHR, textStatus, errorThrown) {
                    console.log(errorMessage,jqXHR.status,textStatus,errorThrown);
                    // 表示を戻す
                    $('html').removeClass('taba-status-loading');
                    navCart.html(navCartOriginHtml);
                    button.removeClass('taba-status-loading').prop('disabled', false).html(buttonOriginHtml);

                    tooltipTarget.tooltipManual(errorMessage);
                    button.find("i").addClassOnce('vivify duration-500 shake',600);
                // 終了処理
                }).always(function(data) {})
            ).done(function() {
                //
                // カートブロックを更新する
                //
                $.ajax({
                    url: cartBlockUpdateUrl,
                    type: 'GET',
                    dataType: 'html',
                }).done(function(html) {
                    // 表示を戻す
                    $('html').removeClass('taba-status-loading');
                    button.removeClass('taba-status-loading').prop('disabled', false).html(buttonOriginHtml);
                    navCart.html(navCartOriginHtml);

                    // ヘッダーのカート部分
                    // $('#taba-block-cart').replaceWith(html);
                    $('#taba-cart-count').html($(html).find('#taba-cart-count').html());
                    $('#taba-nav-cart').find('.taba-inner').html($(html).find('.taba-inner').html());
                    navCart.find('i').addClass('taba-anime-ball');
                    //$('#taba-modal-product-' + $form.find('input[name=product_id]').val()).modal('hide'); // 規格選択モーダルを消す

                    // カート商品のスライダーを再構築
                    cartConfirmListSwiper = createCartConfirmListSwiper();
                    // let swiperParams = cartConfirmListSwiper.params;
                    // cartConfirmListSwiper.destroy(cartConfirmListSwiper);
                    // cartConfirmListSwiper = new Swiper('#taba-cart-confirm-list',swiperParams);

                    // スクロールバーを更新
                    cartConfirmListSwiper.on('afterInit',function(){
                        let megamenu = $('#taba-nav-cart').find('.taba-megamenu');
                        console.log(megamenu.outerHeight());
                        console.log($('#taba-header').height());
                        console.log($(window).height());
                        if (megamenu.outerHeight() + $('#taba-header').height() > $(window).height()) {
                            megamenu.css('height',$(window).height() - $('#taba-header').height());
                        }
                        currentPerfectScrollbar = new PerfectScrollbar(megamenu.get(0), {
                            wheelSpeed: 1,
                            wheelPropagation: true,
                            minScrollbarLength: 20,
                            suppressScrollX: true,
                        });
                    });

                    // // メガメニューを表示する
                    // if (data.done) $('#taba-cart-confirm').trigger('click');

                    tooltipTarget.tooltipManual(mesage); // メッセージの表示
                });
            });

        });
    });
};

/**
 * ツールチップを自動で表示します。
 * 
 * @param mesage
 * @param delay ツールチップが消えるまで時間
 * @return JQuery Object
 */
$.fn.tooltipManual = function(mesage,delay) {
    if (delay == null) delay = 3000;
    return $(this).each(function(i,e){
        let element = $(e);
        //element.tooltip({trigger:'manual',title:mesage}).tooltip('show');
        let tooltip = new bootstrap.Tooltip(element,{'title':mesage,'delay':delay});
        tooltip.show();
        if (delay > 0) {
            setTimeout(function(){
                tooltip.hide();
                tooltip.dispose();
            },delay);
        }
    });
}; 

/**
 * お気に入りの制御
 * 
 * @return JQuery Object
 */
$.fn.favariteControl = function() {
    let iconOn = "favorite";
    let iconOff = "favorite_border";

    return $(this).each(function(i,element){
        $(element).on('click',function() {
            let eventElem = $(this);
            let id = eventElem.attr('data-key');
            let icon = eventElem.find('i');
            let action = (icon.html() == iconOn ? 'off' : 'on');
            let url = (action == "on" ? TABA_JS_CONFIG.favorite.url.add : TABA_JS_CONFIG.favorite.url.delete);

            $.ajax({
                'url': url + '/' + id,
                type: 'POST',
                dataType: "json",
                async:true,
                cache:false,
                timeout: 10000,
                dataType: 'json',
                beforeSend: function(xhr, settings) {
                    // ウェイトカーソルに変更
                    $('html').addClass('taba-status-loading');
                    eventElem.addClass('taba-status-loading');
                    setTimeout(function(){
                        $('html').removeClass('taba-status-loading');
                        eventElem.removeClass('taba-status-loading');
                    },10000);

                    // ボタンを更新中アイコンに変更
                    icon.removeClass().addClass('material-icons');
                    icon.addClass('taba-anime-spin-left').html('sync');
                }
            }).done(function(data) {

            }).fail(function(data) {
                if (data.status == undefined || data.status == null) data.status = "error";
                if (data.message == undefined || data.message == null) data.message = TABA_JS_CONFIG.message.system_error; 
            }).always(function(data) {
                $('html').removeClass('taba-status-loading'); 
                eventElem.removeClass('taba-status-loading');
                // $(eventElemName).prop('disabled', false);

                if (data.message != null) eventElem.tooltipManual(data.message);

                console.log("[STATUS]",data.status,"[MESSAGE]",data.message);

                // アイコン変更
                if (data.status == "success") {
                    icon.html((action == "on" ? iconOn : iconOff)).removeClass().addClass('material-icons vivify duration-500 unfold');
                } else {
                    icon.html(iconOff).removeClass().addClass('material-icons vivify duration-500 shake');
                }
            });
        });

    });
};

/**
 * 注文手続きの画面遷移制御
 * 
 * @param mesage
 * @param delay 
 * @return JQuery Object
 */
$.fn.shoppingControl = function(url) {
	return $(this).each(function(i,element){
		$(this).on($(this).attr('data-trigger'),
		function() {
            loading();
			$('#shopping_order_redirect_to').val($(this).attr('data-path'));
            $('#shopping-form').attr('action',url).submit();
		});
	});
};


// sleep = function(wait,callbackFunc) {
//     var sec = 0;
//     var timer = setInterval(function () {
//         sec++;
//         if (sec >= wait) {
//             clearInterval(timer);
//             if (callbackFunc) callbackFunc();
//         }
//     }, 1000);
// }

var lodingProcessing = false;
var loading = function(visible) {
    if (visible != undefined && visible != null && visible == false) {
        $('body').removeClass('taba-page-move');
        lodingProcessing = true;
        // 5秒間以内はローディングアクションを無効にします。
        setTimeout(function() { lodingProcessing = false; },5000);
    } else if (lodingProcessing == false) {
        lodingProcessing = true;
        $('body').addClass('taba-page-move');
        // 10秒経過してもページ遷移出来なかった場合、エフェクトを無効にします
        setTimeout(function() {
            $('body').removeClass('taba-page-move');
            lodingProcessing = false;
        },10000);
    }
}
 
/**
 * EC-CUBEデフォルトテンプレートの JSを使用しているプラグインがあるため、wrapper関数を作る
 */
var loadingOverlay = function(visible) {
	if (visible != undefined && visible != null && visible == 'hide') {
		loading(false);
	} else {
		loading();
	}
}

/**
 * 
 * @param {*} className 
 * @param {*} delay 
 */
$.fn.addClassOnce = function(className,delay) {
	return $(this).each(function(i,element){
        $(this).addClass(className).delay(delay).queue(function(next){
            $(this).removeClass(className);
            next();
        });
	});
};


document.addEventListener("DOMContentLoaded", function() {
    //
    // tooltipの初期化
    //
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });
});


/**
 * カート商品確認用スライダー
 */
function createCartConfirmListSwiper() {
    if (document.getElementById("taba-cart-confirm-list") != null) {
        return new Swiper('#taba-cart-confirm-list', {
            slidesPerView: 2,
            spaceBetween: 15,
            loop: false,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                },
                576: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 4,
                },
            },
        });
    }
    return null;
};