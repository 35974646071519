/**!
 * Copyright (C) SPREAD WORKS Inc. All Rights Reserved.
 * 
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */
 import Cookies from 'js-cookie'

 window.addEventListener('DOMContentLoaded', (event) => {
 
     const count_save_closed_information_ids = 100;
     let ElmCloseInformationbar = document.getElementsByClassName("closeInformationbar");
     let ElmCloseInformationbars = Array.from(ElmCloseInformationbar);
 
     ElmCloseInformationbars.forEach(function(target) {
         target.addEventListener("click", function() {
             let informationid = this.dataset.informationid;
             this.closest(".taba-informationbar").remove();
             if (informationid) {
                 let closed_information_ids = [];
                 let closed_information_ids_str = "";
                 if (Cookies.get('closed_information_ids')) {
                     closed_information_ids_str = Cookies.get('closed_information_ids');
                     closed_information_ids = closed_information_ids_str.split(',');
                 }
                 closed_information_ids.unshift(informationid);
                 closed_information_ids.splice(count_save_closed_information_ids);
                 closed_information_ids_str = closed_information_ids.join(",");
                 Cookies.set('closed_information_ids', closed_information_ids_str,{ expires: 365 });
             }
         });
     });
 });
 
 